<template>
  <div class="visualChart">
    <div class="histogram">
      <p class="title">儿童视力发育大致情况</p>
    </div>

    <div class="btnGroup" >
      <button @click="go" style="display: none">返回</button>
    </div>

    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: "visualChart",
  data() {
    return {
      list: [],
      imgList: [],
      content: '',
      singleList: [],
      imgSrc: '',
      interval: null,
      flag : -1
    }
  },
  methods: {
    go() {
      this.$router.go(-1);
    },
    pictureSwitching() {
      this.flag += 1;
      if (this.flag > this.singleList.length - 1) {
        this.flag = 0;
      }
      this.imgSrc = this.singleList[this.flag];
    },
    visionEvent() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.flag = -1;
      this.interval = setInterval(this.pictureSwitching, 200);
    },
    // sceneClick() {
    //   this.$router.push({path: '/PopularScienceHome/growUp'});
    // }
  },
  created() {
    let arr = [
      '出生：眼轴长度18.5mm，屈光度+4.00D',
      '3岁：眼轴男性：22.2mm，女性：21.5mm 屈光度男性：+2.33D，女性+2.96D',
      '7-8岁：眼轴22.8mm，屈光度：+1.00D',
      '14岁：眼轴男性：23.1mm 女性：22.7mm，屈光度：男性+0.93D，女性+0.62D'
    ];
    let imgList = [
      ['http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_2.jpg','http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_2.jpg','http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_2.jpg','http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_2.jpg'],
      ['http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_2.jpg','http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_2.jpg'],
      ['http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_2.jpg','http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_2.jpg'],
      ['http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_2.jpg','http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/drive_2.jpg']];
    this.imgList = imgList;
    this.list = arr;
    this.singleList = this.imgList[0];
    this.content = this.list[0];
    this.visionEvent();
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

}
</script>

<style scoped lang="scss">
.visualChart {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/pedia_background.jpg") no-repeat center center;
  background-size: cover;
}
.histogram {
  height: 90%;
  background: url("http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/histogram.png") no-repeat center center;
  background-size: contain;
}
.title {
  line-height: 40px;
  font-weight: 600;
  font-size: 18px;
}
.btnGroup {
  width: 100%;
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnGroup button {
  margin: 15px 30px;
  padding: 10px 80px;
  background: transparent;
  border: 1px solid #333;
  color: #333;
  font-size: 18px;
}

</style>
